<template>
  <transition name="fade">
    <div class="layout">
      <Layout>
        <Header class="layout-header">
          <div class="layout-logo">
            <Image
              src="/favicon.png"
              alt="logo"
              height="30px"
              width="30px"
              fit="cover"
            />
            <div class="logo-text"><h2>达摩学院</h2></div>
          </div>
        </Header>
        <Content :style="{ padding: '10px' }">
          <router-view></router-view>
          <BackTop></BackTop>
        </Content>
        <Footer>
          <GlobalFooter :links="links" :copyright="copyright" />
        </Footer>
      </Layout>
    </div>
  </transition>
</template>

<script setup>
import { Image } from "view-ui-plus";
import { onMounted, ref } from "vue";
import wx from "weixin-js-sdk";

const links = ref([
  {
    key: "河北高速公路集团有限公司",
    title: "河北高速公路集团有限公司",
    href: "https://www.hbgs.com.cn",
    blankTarget: true,
  },
  {
    key: "培训考核云平台",
    title: "培训考核云平台",
    href: "https://yun1.hbgs.online/kaoshi",
    blankTarget: true,
  },
]);
const copyright = ref(
  `河北高速公路集团有限公司 © ${new Date().getFullYear()} 版权所有 All Rights Reserved`
);
const wxUtils = () => {
  "undefined" == typeof WeixinJSBridge
    ? document.addEventListener
      ? document.addEventListener("WeixinJSBridgeReady", onBridgeReady, !1)
      : document.attachEvent &&
        (document.attachEvent("WeixinJSBridgeReady", onBridgeReady),
        document.attachEvent("onWeixinJSBridgeReady", onBridgeReady))
    : onBridgeReady();
};
const onBridgeReady = () => {
  wx.hideOptionMenu();
};
onMounted(() => {
  wxUtils();
});
</script>

<style>
body {
  width: 100%;
  overflow-x: hidden;
}
.ivu-layout-header,
.ivu-layout-footer {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.layout-header {
  background: #fff !important;
  border-bottom: 1px solid #eee;
  box-shadow: 0 4px 4px 0 #eee;
  position: sticky;
  top: 0;
  z-index: 1;
}
.layout-logo {
  min-width: 300px;
  height: 64px;
  border-radius: 3px;
  float: left;
  position: relative;
}
.layout-logo .logo-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}
.ivu-layout-footer {
  background: #fff !important;
  border-top: #eee 1px solid;
}
</style>
