<template>
  <div id="content">
    <PublicHeader :style="{ marginBottom: '8px' }" />
    <transition name="fade">
      <div>
        <Card :dis-hover="true" class="no_select" v-if="showBasics">
          <Space>
            <p>请选择考试场次：</p>
            <Cascader
              v-if="false"
              :data="basics"
              :load-data="loadBasic"
              @on-change="handleChange"
              transfer
            />
            <Select clearable style="width: 200px" @on-change="handleSelect">
              <Option
                v-for="(item, index) in basics"
                :value="item.value"
                :key="index"
                >{{ item.label }}</Option
              >
            </Select>
          </Space>
        </Card>
        <Card :dis-hover="true" class="no_select" :style="{ marginTop: '8px' }">
          <div class="content">
            <router-view :key="activeDate"></router-view>
          </div>
        </Card>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import { Message } from "view-ui-plus";
import PublicHeader from "./PublicHeader.vue";
const _axios = axios.create({
  baseURL: "//yun1.hbgs.online/kaoshi",
  timeout: 3000,
  headers: { "content-type": "application/x-www-form-urlencoded" },
});
_axios.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => Promise.reject(error)
);
_axios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
export default {
  name: "HelloWorld",
  components: {
    PublicHeader,
  },
  created() {
    if (this.$route.meta?.userid == null) {
      this.$router.replace({ path: "/Login" });
    } else {
      console.log(this.$route.meta?.userid);
      let userid = (this.userid = this.$route.meta?.userid);
      this.username = this.$route.meta?.username;
      _axios
        .post("index.php?content-phone-muinews-findbasic", { userid })
        .then((resp) => {
          let basics = [...resp.basics];
          for (let item of basics) {
            let basic = {
              value: item.basicid,
              label: item.basic,
              children: [],
              loading: false,
              basicareaid: item.basicareaid,
              basicareaid2: item.basicareaid2,
              basicclosed: item.basicclosed,
              basicdescribe: item.basicdescribe,
              basicid: item.basicid,
              basicknows: item.basicknows,
              basicsection: item.basicsection,
              basicshowday: item.basicshowday,
              basicshowtime: item.basicshowtime,
              basicsubjectid: item.basicsubjectid,
              basicthumb: item.basicthumb,
              basiczt: item.basiczt,
              groupid: item.groupid,
              pxorder: item.pxorder,
            };
            // if (["28", "1659", "1664"].includes(item.basicid))
            this.basics.push(basic);
            this.basics.forEach(this.queryBasic);
          }
        });
    }
  },
  mounted() {},
  data() {
    return {
      activeDate: new Date().getTime(),
      userid: "",
      username: "",
      showBasics: false,
      basics: [],
      basicsName: [{ value: "", label: "考场名称" }],
    };
  },
  methods: {
    async loadBasic(item, callback) {
      item.loading = true;
      item.children.length = 0;
      setTimeout(() => {
        item.children.push({ value: "tiku", label: "全部题库" });
        item.loading = false;
        callback();
      }, 1000);
    },
    async queryBasic(item) {
      // 查询考场是否开启考试或已完成考试
      let data = {
        userid: this.userid,
        basicid: item.basicid,
        ehtype: 2,
        banben: "20230511",
      };
      await _axios
        .post("index.php?content-phone-muinews-getexaminfonews", data)
        .then((resp) => {
          setTimeout(() => {
            resp.statusCode == 200 && (this.showBasics = true);
          }, 1000);
        })
        .catch(() => Message.error("获取信息超时，请重试！"));
    },
    handleChange(value, selectedData) {
      let basicid = value[0];
      let ehtype = value[1];
      let item = selectedData[0];
      if (!item) {
        this.$router.replace({ name: "home" });
        return;
      }
      let basicknows = [];
      for (let key in item.basicknows) {
        let section = item.basicknows[key];
        for (let knowid in section) {
          basicknows.push(knowid);
        }
      }
      // 获取章节题目id
      switch (ehtype) {
        default: {
          this.$router.beforeEach(async (to, from, next) => {
            to.meta = { userid: this.userid, basicid, basicknows, ehtype };
            next();
          });
          this.$router.replace({
            name: "lesson",
            query: {
              t: new Date().getTime(),
            },
          });
          break;
        }
      }
    },
    handleSelect(value) {
      if (!value) {
        this.$router.replace({ name: "home" });
        return;
      }
      let selectItem = this.basics.find((item) => item.value == value);
      console.log(selectItem);
      // this.$Modal.confirm({
      //   title: `${selectItem.label}提示`,
      //   content: `<p>请选择模式：</p><p><b>确定：</b>将进入正式考试界面，答题界面仅供使用，可能存在位置问题。<b>测试功能，不保证系统正常运行！</b></p><p><b>取消：</b>进入题库搜题模式。</p>`,
      //   onOk: async () => {
      //     // 查询考场是否开启考试或已完成考试
      //     let data = {
      //       userid: this.userid,
      //       basicid: selectItem.basicid,
      //       ehtype: 2,
      //       banben: "20230511",
      //     };
      //     await _axios
      //       .post("index.php?content-phone-muinews-getexaminfonews", data)
      //       .then((resp) => {
      //         setTimeout(() => {
      //           if (resp.statusCode == 200) {
      //             this.$router.addRoute({
      //               path: "/exampage",
      //               component: () => import("@/components/ExamPage.vue"),
      //               name: "exampage",
      //               meta: {
      //                 userid: this.userid,
      //                 basicid: selectItem.value,
      //                 basicknows: selectItem.basicknows,
      //                 ehtype: 2,
      //               },
      //             });
      //             this.$router.beforeEach((to, from, next) => {
      //               if (to.path == "/exampage") {
      //                 to.meta = {
      //                   userid: this.userid,
      //                   basicid: selectItem.value,
      //                   basicknows: selectItem.basicknows,
      //                   ehtype: 2,
      //                 };
      //                 next();
      //               }
      //             });
      //             this.$router.push("/exampage");
      //           } else {
      //             this.$Notice.warning({
      //               title: "提示",
      //               desc: "考场未开启考试，将为您展示题库内容。",
      //             });
      //             this.handleChange([value, "lessons"], [selectItem]);
      //           }
      //         }, 1000);
      //       })
      //       .catch(() => Message.error("获取信息超时，请重试！"));
      //   },
      //   onCancel: () => {
      //     this.handleChange([value, "lessons"], [selectItem]);
      //   },
      // });

      this.handleChange([value, "lessons"], [selectItem]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

li.ivu-select-item {
  display: block;
}

.no_select {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
</style>
